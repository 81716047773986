.Content {
  width: calc(100% - 350px);
}

.Video-container {
  position: relative;
}

.Overlay,
.OverlayNumber {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  flex-direction: column;
  font-weight: bold;
}

.OverlayTitle {
  font-size: 25px;
  margin-bottom: 25px;
}

.OverlayNumber {
  font-size: 80px;
  margin: 0;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  background-color: black;
  box-shadow: 0 0 0 2px white, 0 0 0 8px black, 0 0 0 12px white;
}

.Video {
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 280px);
  display: block;
}

.Wave {
  position: relative;
  overflow: hidden;
  height: 40px;
  width: 100%;
}

.WaveBgc,
.SoundWave {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.WaveBgc {
  z-index: 10;
  background: transparent url(../../img/wave.svg) center / auto 60px repeat-x;
  opacity: 0.75;
}

.Start,
.Stop,
.Preview,
.Save {
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
  appearance: none !important;
  -webkit-appearance: none !important;
  --webkit-appearance: none !important;
  line-height: 1;
  letter-spacing: 0.1em;
  font-weight: bold;
  border: none;
  border-radius: 2px;
}

.Start,
.Stop {
  color: var(--color-warn);
  background-color: var(--color-primary);
}

.Save[disabled],
.Start[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.Preview {
  background-color: rgba(255, 255, 255, 0.5);
  color: var(--color-secondary);
}

.Save {
  background-color: var(--color-success);
  color: white;
}

.StopDot,
.StartDot {
  background-color: var(--color-warn);
  height: 12px;
  width: 12px;
  margin-right: 0.75rem;
}

.StartDot {
  border-radius: 50%;
}


.modal90{
  max-width: 80% !important;
}