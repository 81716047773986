.Scoreboard,
.Scoreboard-team-score {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Scoreboard {
  color: white;
}

.Scoreboard-team-label {
  font-weight: bold;
}

.Scoreboard-team-thumb {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12),
    0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 0.5rem;
}

.Scoreboard-team-score {
  background-color: var(--color-success);
  border-radius: 2px;
  color: var(--color-primary);
  font-weight: bold;
  height: 25px;
  width: 25px;
  margin: 0 0.5rem;
  font-size: 13px;
}
