.Information {
  padding: 0 20px 20px;
  width: 350px;
}

.Sponsor {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 90px;
  width: 100%;
}

.opaque {
  opacity: 0.75;
}
