html,
body,
#root,
.App,
.custom-bg-secondary,
.container {
  height: 100%;
}

:root {
  --color-primary: #091e3c;
  --color-secondary: #253a60;
  --color-accent: #33445a;
  --color-success: #49df92;
  --color-warn: #fc4044;
  height: 100%;
}

ul.list-team {
  list-style-type: circle;
}

ul.list-team > li {
  text-align: left;
}

.h-full {
  height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.custom-bg-primary {
  background-color: var(--color-primary) !important;
}
.custom-bg-accent {
  background-color: var(--color-accent);
}
.custom-bg-secondary {
  background-color: var(--color-secondary);
}

.shadow-dp2 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.shadow-dp3 {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12),
    0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.text-sm {
  font-size: 0.75rem;
}
.text-success {
  color: var(--color-success);
}

.border-0 {
  border: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.message-centered {
  padding: 2%;
}
